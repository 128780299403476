export default {
  name: '313C',
  recruitment_game: 'Rekrutierungs-Spiel',
  back: 'Zurück',
  cancel: 'Abbrechen',
  continue: 'Fortsetzen',
  skip: 'Überspringen',
  start: 'Start',
  close: 'Schließen',
  help: 'Hilfe',
  time_left: 'Verbleibende Zeit',
  progress: 'Fortschritt',
  privacy_policy: 'Datenschutzrichtlinien',
  cookies_policy: 'Cookie-Richtlinien und -Bedingungen',
  faq: 'Frag- und Antworten',
  messages: 'Nachrichten',
  unread: 'ungelesen',
  start_recording: 'Aufzeichnung starten',
  stop_recording: 'Aufzeichnung stoppen',
  remove_record: 'Aufzeichnung entfernen',
  play_video: 'Abspielen',
  stop_video: 'Stop',
  mute_video: 'Stumm',
  unmute_video: 'Stummschaltung aufheben',
  replay_video: 'Erneut abspielen',
  choose: 'Wählen',
  badges: {
    title: 'Auszeichnungen',
    badge: 'Auszeichnung',
    my_badges: 'Meine Auszeichnungen',
    received: 'Du bekommst eine Auszeichnung',
    continue: 'Spiele weiter',
    show_badges: 'Sieh dir Auszeichnungen an',
    show_badge: 'Sieh dir die belohnung an',
  },
  record_test: {
    intro: 'KAMERA- UND MIKROFON-EINSTELLUNGSTEST',
    content:
      'Bevor Sie mit dem Tool beginnen, testen Sie Ihre Einstellungen. Im untenstehenden Feld sollten Sie das Bild Ihrer Kamera sehen. Wenn Sie es sehen, bedeutet dies, dass Ihre Einstellungen höchstwahrscheinlich korrekt sind - um sicherzugehen, nehmen Sie ein Video auf und spielen es ab. Wenn Sie das Bild der Kamera nicht sehen, die Aufnahme nicht abspielen können oder andere Zweifel haben, klicken Sie auf die Schaltfläche „PROBLEMLÖSUNG“.',
    troubleshoot: 'PROBLEMLÖSUNG',
  },
  questions: {
    progress: 'Frage',
    accept: 'Bestätigen',
    question_has_already_been_answered: 'Diese Frage wurde bereits beantwortet.',
  },
  not_found: {
    code: '404',
    title: 'Seite existiert nicht',
    content:
      'Die Seite, die Sie suchten, wurde nicht gefunden. Es könnte gelöscht, umbenannt oder gar nicht existiert haben.',
  },
  phone: 'Telefonnummer',
  select_language: 'Sprache wählen / Choose language',
  title:
    'Diese Seite verwendet Cookies, um Ihnen ein großartiges Benutzererlebnis zu bieten. Durch die Nutzung dieser Seite erklären Sie sich mit der Verwendung von',
  link: 'Cookies durch uns einverstanden.',
  button: 'Schließen',
  warning: {
    title: 'Es ist ein Fehler aufgetreten',
    content: 'Bitte versuchen Sie es erneut und melden Sie uns, wenn sich der Fehler wiederholt',
    content_without_email:
      'Bitte versuchen Sie es erneut und melden Sie uns den Fehler, wenn er sich wiederholt',
    confirm: 'Schließen',
  },
  end_time: {
    title: 'Fehler melden',
    content:
      'Wenn Sie einen Fehler beim Spielen bemerken, kontaktieren Sie uns bitte per E-Mail unter dieser Adresse:',
  },
  record_video_error: {
    title: 'Kein Zugriff auf Kamera und/oder Mikrofon.',
    content:
      'Um diese Aufgabe abzuschließen, ändern Sie Ihre Einstellungen und erlauben Sie der Anwendung den Zugriff auf Ihre Kamera und/oder Ihr Mikrofon.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Nicht unterstütztes Gerät</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Um fortzufahren, verwenden Sie bitte ein <strong>anderes Gerät und/oder einen anderen Browser.</strong></p>
        <p>Das von Ihnen verwendete Bewerbungstool enthält mindestens eine Aufgabe, bei der Sie Ihre Antwort in Videoformat aufnehmen müssen. Leider ermöglichen nicht alle Geräte und/oder Browser dies in korrekter Weise.</p>
        <p><strong>Wenn Sie diesen Bildschirm sehen, bedeutet dies, dass Ihr Gerät und/oder Browser nicht die korrekte Aufnahme und Übermittlung einer Videodatei garantieren.</strong> Unten finden Sie empfohlene Einstellungen.</p>
        <br>
        <p><strong>Desktop-Geräte:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Mobile Geräte:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Sind Sie sicher, dass Sie die Aufgabe überspringen möchten?',
    content:
      '<p>Das Aufzeichnen einer Videoantwort für diese Aufgabe ist nicht zwingend erforderlich, aber empfohlen – es wird uns helfen, Sie besser kennenzulernen. Sind Sie sicher, dass Sie die Aufzeichnung einer Videoantwort überspringen möchten?</p>',
  },
  skip_stage_with_record: {
    title: 'Sind Sie sicher, dass Sie die Aufgabe überspringen möchten?',
    content:
      '<p>Sie beabsichtigen, das Speichern der aufgezeichneten Videoantwort zu überspringen – sie wird für die Person, die den Auswahlprozess durchführt, nicht verfügbar sein. Das Hochladen einer Videoantwort für diese Aufgabe ist nicht obligatorisch, aber empfohlen – es wird uns helfen, Sie besser kennenzulernen. Sind Sie sicher, dass Sie das Speichern der aufgezeichneten Videoantwort überspringen möchten?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Problemlösung bei Kamera und Mikrofon</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Wenn die Aufnahme oder Wiedergabe nicht korrekt ist, können Sie einige Schritte befolgen, die das Problem lösen sollten. Die häufigsten Ursachen für Probleme liegen in den Einstellungen des Geräts, einschließlich anderer Programme oder Anwendungen, die gleichzeitig die Kamera und/oder das Mikrofon nutzen.</p>
      <p>Mögliche Problemlösungen:</p>
      <ol>
        <li>Stellen Sie sicher, dass der Browser die Berechtigungen hat, das Mikrofon und die Kamera zu nutzen. Wenn nicht, ändern Sie die Einstellungen und aktualisieren Sie die Seite.</li>
        <li>Stellen Sie sicher, dass keine andere Anwendung oder Programm derzeit die Kamera/das Mikrofon verwendet (z.B. MS Teams, Photo Booth oder FaceTime) - schließen Sie die Anwendungen, die die Nutzung der Kamera durch den Browser blockieren, und aktualisieren Sie die Seite.</li>
        <li>Überprüfen Sie, ob Ihr Browser auf die neueste Version aktualisiert ist - wenn nicht, führen Sie ein Update durch und starten den Test erneut.</li>
        <li>Wenn die oben genannten Schritte nicht geholfen haben, versuchen Sie es mit einem anderen Browser oder Gerät.</li>
      </ol>
      <p>Wenn das Problem weiterhin besteht, lassen Sie es uns wissen unter { supportEmail }, indem Sie Informationen über das Gerät und den Browser senden, bei denen das Problem auftritt.</p>
      </div>
      <hr>
    `,
  },
  recaptcha: 'Recaptcha bestätigen',
  phone_incorrect: 'Geben Sie eine gültige Telefonnummer ein',
  missing_answer_error:
    'Um fortzufahren, wählen Sie zwei Antworten: eine mit dem Daumen nach oben und eine mit dem Daumen nach unten.',
  rotate_phone_video:
    'Drehen Sie Ihr Telefon horizontal, um das Video in größerer Größe anzuzeigen',
  rotate_phone_image: 'Drehen Sie Ihr Telefon horizontal, um das Bild in größerer Größe anzuzeigen',
};
