export default {
  name: '313C',
  recruitment_game: 'Рекрутингова гра',
  back: 'назад',
  cancel: 'Скасувати',
  continue: 'Продовжити',
  skip: 'Пропустити',
  start: 'Старт',
  close: 'Закрити',
  help: 'Довідка',
  time_left: 'Залишилось часу',
  progress: 'Прогрес',
  privacy_policy: 'Політика конфіденційності',
  cookies_policy: 'Політика використання файлів cookie і Умови',
  faq: 'Питання і відповіді',
  messages: 'Повідомлення',
  unread: 'непрочитана',
  start_recording: 'Почати запис',
  stop_recording: 'Зупинити запис',
  remove_record: 'Видалити запис',
  play_video: 'Відтворити',
  stop_video: 'Зупинити',
  mute_video: 'Вимкнути звук',
  unmute_video: 'Увімкнути звук',
  replay_video: 'Відтворити знову',
  choose: 'Виберіть',
  badges: {
    title: 'Значки',
    badge: 'Значок',
    my_badges: 'Мої Значки',
    received: 'Ви отримали значок',
    continue: 'Грайте далі',
    show_badges: 'Переглянути значки',
    show_badge: 'побачти нагороду',
  },
  record_test: {
    intro: 'ТЕСТ НАЛАШТУВАНЬ КАМЕРИ ТА МІКРОФОНА',
    content:
      'Перед початком користування інструментом протестуйте свої налаштування. У полі нижче ви повинні бачити зображення з вашої камери. Якщо ви його бачите, це означає, що ваші налаштування, ймовірно, правильні - щоб бути впевненими, запишіть та відтворіть відео. Якщо ви не бачите зображення з камери, не можете відтворити запис або маєте інші сумніви, натисніть кнопку «ВИРІШЕННЯ ПРОБЛЕМ».',
    troubleshoot: 'ВИРІШЕННЯ ПРОБЛЕМ',
  },
  questions: {
    progress: 'Питання',
    accept: 'Підтвердити',
    question_has_already_been_answered: 'Це питання вже було відповідане.',
  },
  not_found: {
    code: '404',
    title: 'Сторінка не існує',
    content:
      'Сторінку, яку ви шукали, не знайдено. Можливо, її видалили, змінили назву або вона взагалі не існує',
  },
  phone: 'Номер телефону',
  select_language: 'Виберіть мову / Choose language',
  title:
    'Ця сторінка використовує файли cookie, щоб забезпечити вам чудові користувацькі враження. Користуючись 313C, ви погоджуєтеся на використання нами',
  link: 'файлів cookie',
  button: 'Закрити',
  warning: {
    title: 'Сталася помилка',
    content: 'Спробуйте ще раз, і якщо помилка повторюється, повідомте нас за адресою',
    content_without_email:
      'Спробуйте ще раз, і якщо помилка повторюється, зверніться до технічної підтримки.',
    confirm: 'Закрити',
  },
  end_time: {
    title: 'Повідомити про помилку',
    content:
      "Якщо під час вашої гри сталася помилка, будь ласка, зв'яжіться з нами за адресою електронної пошти:",
  },
  record_video_error: {
    title: 'Відсутній доступ до камери та/або мікрофона.',
    content:
      'Щоб завершити це завдання, змініть налаштування та дозвольте додатку отримати доступ до вашої камери та/або мікрофона.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Непідтримуваний пристрій</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Дляпродовження,будьласка,використовуйте <strong>іншийпристрійі/абоіншийбраузер.</strong></p>
        <p>Інструментдлярекрутингу,якийвиплануєтевикористовувати,міститьпринаймніоднезавдання,якевимагаєвасзаписатисвоювідповідьуформатівідео.Нажаль,невсіпристроїі/абобраузеридозволяютьцезробитиправильно.</p>
        <p><strong>Якщовибачитецейекран,цеозначає,щовашпристрійі/абобраузернегарантуютьправильнезаписуваннятанадсиланнявідеофайлу.</strong> Нижченаведенорекомендованіналаштування.</p>
        <br>
        <p><strong>Настільніпристрої:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Мобільніпристрої:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Ви впевнені, що бажаєте пропустити завдання?',
    content:
      "<p>Запис відеовідповіді для цього завдання не обов'язковий, але рекомендується - це допоможе нам краще вас познати. Ви впевнені, що бажаєте пропустити запис відеовідповіді?</p>",
  },
  skip_stage_with_record: {
    title: 'Ви впевнені, що бажаєте пропустити завдання?',
    content:
      "<p>Ви збираєтеся пропустити збереження записаної відеовідповіді - вона не буде доступна для особи, яка проводить відбір. Відправлення відеовідповіді для цього завдання не є обов'язковим, але рекомендується - це допоможе нам краще вас познати. Ви впевнені, що бажаєте пропустити збереження записаної відеовідповіді?</p>",
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Усунення проблем з камерою та мікрофоном</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Якщо запис або відтворення не коректне, ви можете слідувати кільком крокам, які мають вирішити проблему. Найчастіші причини проблем виникають через налаштування пристрою, включаючи інші програми або додатки, які одночасно використовують камеру та/або мікрофон.</p>
      <p>Можливі рішення проблеми:</p>
      <ol>
        <li>Переконайтеся, що браузер має дозволи на використання мікрофону та камери. Якщо ні, змініть налаштування та оновіть сторінку.</li>
        <li>Переконайтеся, що жодна інша програма або додаток не використовує камеру/мікрофон наразі (наприклад, MS Teams, Photo Booth або FaceTime) - закрийте додатки, які блокують можливість використання камери браузером, та оновіть сторінку.</li>
        <li>Перевірте, чи ваш браузер оновлено до останньої версії - якщо ні, виконайте оновлення та повторіть тест.</li>
        <li>Якщо вищевказані кроки не допомогли, спробуйте використати інший браузер або пристрій.</li>
      </ol>
      <p>Якщо проблема залишається, повідомте нас на { supportEmail }, надсилаючи інформацію про пристрій та браузер, на яких виникає проблема.</p>
      </div>
      <hr>
    `,
  },
  recaptcha: 'Позначте Recaptcha',
  phone_incorrect: 'Введіть правильний номер телефону',
  missing_answer_error:
    'Щоб продовжити, виберіть дві відповіді: одну з пальцем вгору і одну з пальцем вниз.',
  rotate_phone_video: 'Поверніть телефон горизонтально, щоб переглянути відео у більшому розмірі',
  rotate_phone_image:
    'Поверніть телефон горизонтально, щоб переглянути зображення у більшому розмірі',
};
