import Axios from 'axios';
import axiosRetry from 'axios-retry';
import { env } from '@/env';
import { errorResponseHandler } from '@/common/axios/errorResponseHandler';
import type { AxiosError, AxiosInstance } from 'axios';

const instance: AxiosInstance = Axios.create({
  baseURL: env.apiUrl,
  auth:
    env.basicAuthUserName && env.basicAuthPassword
      ? {
          username: env.basicAuthUserName,
          password: env.basicAuthPassword,
        }
      : undefined,
  withCredentials: true,
});

axiosRetry(instance, {
  retries: 3,
  retryDelay: (retryCount: number) => retryCount * 5000,
  retryCondition: (error: AxiosError): boolean => {
    const status = error.response?.status || 0;
    const isServerIssue = !error.response || error.code === 'ECONNABORTED';
    const isNetworkIssue = !error.response || error.code === 'ERR_NETWORK';
    const isServerError = status >= 500 && status < 600;

    return isServerIssue || isNetworkIssue || isServerError;
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => errorResponseHandler(error)
);

export default instance;
